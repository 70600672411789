import React, { useEffect, useState } from 'react';

import { AiFillAppstore, AiOutlinePlus } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { NumberParam, useQueryParam } from 'use-query-params';

import { getTotals } from 'src/api/incomes';
import { getTaxIncomeSummary } from 'src/api/users';
import { useYear } from 'src/hooks';
import {
  Toastify,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Button,
} from 'src/modules';
import store from 'src/stores';
import { formatCurrency } from 'src/utils';

import TabDividendsInterest from '../components/TabDividendsInterest';
import { checkGrossColor } from '../ExpensesTab';
import ExpensesTabTotals from '../ExpensesTab/Components/ExpensesTabTotals';
import { TotalsContainer } from '../ExpensesTab/styles';

import AddPAYE from './Components/AddPAYE';
import AddSelfEmployment from './Components/AddSelfEmployment';
import TabAll from './Components/TabAll';
import TabPAYE from './Components/TabPAYE';
import TabSelfEmployed from './Components/TabSelfEmployed';
import IncomesTabTotals from './IncomesTabTotals';
import {
  Container,
  Tabs,
  Tab,
  TabsHeader,
  TabsHeaderContent,
  TabDetails,
  NewItemButton,
  Select,
  TaxType,
} from './styles';

function IncomesTab() {
  const incomes = store.incomes;

  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const [activeTab, setActiveTab] = useState('All');

  const [showAddPayePage, setShowAddPayePage] = useState(false);
  const [showAddSelfEmployedPage, setShowAddSelfEmployedPage] = useState(false);
  const [tabIndexClicked, setTabIndexClicked] = useState(0);
  const [grossColor, setGrossColor] = useState('#333');

  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);

  const [incomeTypeSelected, setIncomeTypeSelected] = useState(false);

  const {
    data: taxIncomeSummary,
    isLoading: isLoadingTaxIncomeSummary,
    refetch,
  } = useQuery('getTaxIncomeSummary', () => getTaxIncomeSummary(userId, year), {
    onError: ({ response }) => {
      Toastify.toast.error(response.data.message);
    },
  });

  const {
    data: dataTotals,
    isLoading: isLoadingTotals,
    refetch: refetchIncomesTotals,
  } = useQuery('getIncomesTotals', () => getTotals(userId, year), {
    onError: ({ response }) => {
      Toastify.toast.error(response.data.message);
    },
  });

  useEffect(() => {
    incomes.setRefetchSummary(refetch);
    incomes.setRefetchTotals(refetchIncomesTotals);
  }, []);

  const onTabChange = (tabName: string, index: number) => {
    setIncomeTypeSelected(false);
    setActiveTab(tabName);

    if (index || index === 0) {
      setTabIndexClicked(index);
    }
  };

  /* const onOpenAddItemInTabSelected = (incomeType: string) => {
    switch (activeTab) {
      case 'Employment':
        setShowAddPayePage(true);
        setShowAddCisPage(false);
        setShowAddPropertyPage(false);
        setShowAddSelfEmployedPage(false);
        break;
      case 'Self Employed':
        setShowAddSelfEmployedPage(true);
        setShowAddPayePage(false);
        setShowAddCisPage(false);
        setShowAddPropertyPage(false);
        break;
      default:
        break;
    }
  }; */

  const onSelectIncomeType = (incomeType: string) => {
    setIncomeTypeSelected(true);

    if (incomeType === 'employment') {
      setShowAddPayePage(true);
      setShowAddSelfEmployedPage(false);
      setModal(false);
    } else if (incomeType === 'self_employed') {
      setShowAddSelfEmployedPage(true);
      setShowAddPayePage(false);
      setModal(false);
    }
  };

  useEffect(() => {
    if (!isLoadingTotals) {
      checkGrossColor(
        dataTotals.total_incomes,
        dataTotals.total_expenses,
        setGrossColor,
      );
    }
  }, [isLoadingTotals]);

  return (
    <>
      <Container>
        <TabsHeader>
          <TabsHeaderContent>
            {!isLoadingTaxIncomeSummary &&
              taxIncomeSummary?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    onClick={() => onTabChange(item.title, index)}
                    tabIndexClicked={index === tabIndexClicked}
                  >
                    {item.title !== 'All' ||
                      (item.tax_value < 1 && (
                        <AiFillAppstore color="#57E4CD" size={26} />
                      ))}

                    <TabDetails>
                      <span className="title">
                        {item.title === 'Employment' ? 'PAYE' : item.title}
                      </span>
                      <span className="value">
                        {formatCurrency(item.value)}
                      </span>

                      {item.tax_value > 0 && (
                        <>
                          <TaxType taxType={item.tax_type}>
                            {item.tax_type}
                          </TaxType>
                          <TaxType taxType={item.tax_type}>
                            {item.tax_value &&
                              formatCurrency(item.tax_value.toFixed(2))}
                          </TaxType>
                        </>
                      )}
                    </TabDetails>
                  </Tab>
                );
              })}

            <NewItemButton onClick={() => setModal(true)}>
              <AiOutlinePlus size={18} />
            </NewItemButton>
          </TabsHeaderContent>

          {isLoadingTotals && <span>Loading...</span>}

          <TotalsContainer>
            {dataTotals && <IncomesTabTotals totals={dataTotals} />}

            {dataTotals && (
              <ExpensesTabTotals totals={dataTotals} grossColor={grossColor} />
            )}
          </TotalsContainer>
        </TabsHeader>

        <Tabs>
          {activeTab === 'All' && !incomeTypeSelected && (
            <div title="Tab All">
              <TabAll />
            </div>
          )}

          {activeTab === 'Employment' && !incomeTypeSelected && (
            <div onClick={() => onTabChange('Employment')} title="Tab PAYE">
              <TabPAYE />
            </div>
          )}

          {showAddPayePage && (
            <AddPAYE
              setShowAddPayePage={setShowAddPayePage}
              setIncomeTypeSelected={setIncomeTypeSelected}
            />
          )}

          {activeTab === 'Self Employed' && !incomeTypeSelected && (
            <div
              onClick={() => onTabChange('Self Employed')}
              title="Tab Self Employed"
            >
              <TabSelfEmployed />
            </div>
          )}

          {activeTab === 'Dividends Interest' && !incomeTypeSelected && (
            <div
              onClick={() => onTabChange('Dividends Interest')}
              title="Tab Dividends Interest"
            >
              <TabDividendsInterest tab="incomesTab" />
            </div>
          )}
          {showAddSelfEmployedPage && (
            <AddSelfEmployment
              setShowAddSelfEmployedPage={setShowAddSelfEmployedPage}
              setIncomeTypeSelected={setIncomeTypeSelected}
            />
          )}
        </Tabs>

        <Modal centered isOpen={modal} toggle={modalToggle}>
          <ModalHeader toggle={modalToggle}>Income Type</ModalHeader>
          <ModalBody
            className="d-flex flex-wrap"
            style={{ flexDirection: 'column', justifyContent: 'center' }}
          >
            <Select onChange={(e) => onSelectIncomeType(e.target.value)}>
              <option value="">Select an option</option>
              <option value="employment">PAYE</option>
              <option value="self_employed">Self Employed</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={modalToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
}

export default IncomesTab;
