import styled from 'styled-components';

type Props = {
  tabIndexClicked?: boolean;
};

export const Footer = styled.div`
  display: flex;
  column-gap: 130px;
`;

export const NotesSection = styled.div`
  > div {
    height: 116px;
    width: 100%;
    max-width: 446px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 10px;
  }
`;

export const SplitSection = styled.div``;

export const FooterTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 20px;
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .split-range-label {
    font-size: 18px;
    margin-bottom: 30px;
    position: relative;
    right: 5px;
  }
`;

export const SliptRange = styled.input`
  -webkit-appearance: none;
  margin-right: 15px;
  width: 366px;
  height: 2px;
  background: #d8d8d8;
  border-radius: 5px;

  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 40px;
    border: 2px solid #191424;
    border-radius: 50%;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
    background-color: #fff;
    background-image: url('/icons/code-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const PrevNextFields = styled.div`
  display: flex;
  column-gap: 20px;
  margin: 0px 20px 30px;
`;

export const OtherInformations = styled.div`
  padding: 0 15px;
`;

export const SubmitButton = styled.button`
  border: none;
  border-radius: 48px;
  background-color: #57e4cd;
  height: 38px;
  padding: 0 24px;
  font-size: 18px;
  font-weight: 600;
  color: #444;
`;

export const EmployerNameSection = styled.div`
  width: 250px;
`;

export const StartDateSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputDate = styled.input`
  border: none;
  background: transparent;
`;

export const Logo = styled.img`
  width: 70px;
  border-radius: 5px;
`;

export const StartDateLabel = styled.label`
  font-weight: 600;
  font-size: 15px;
`;

export const Text = styled.span`
  position: relative;
  top: 5px;
`;

export const SaveCancelSection = styled.div`
  display: flex;
`;

export const StudentLoanTypeSection = styled.div`
  display: flex;
  width: 50%;
`;

export const IncomeGrossSection = styled.div`
  position: relative;
`;

export const IncomeTaxSection = styled(IncomeGrossSection)``;

export const IncomeNetSection = styled(IncomeGrossSection)``;

export const TabDividends = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  margin-top: 55px;
  background: #fff;
  width: 103px;
  height: 103px;
  border-radius: 6px;
  border: 2px solid
    ${(props) => (props.tabIndexClicked ? '#191424' : '#D8D8D8')};

  &:active {
    border: 1px solid #191424;
  }
`;

export const TabDetails = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  .icon {
    margin-bottom: 40px;
  }

  .title {
    margin-top: 20px;
    font-weight: bold;
    font-size: 14px;
  }

  .value {
    font-size: 12px;
    color: #737373;
    margin-bottom: 10px;
  }
`;

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;
