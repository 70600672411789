/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';

import { BsFillTrashFill, BsBank, BsUpload } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';

import {
  getIncomeTransactions,
  deleteTransaction,
  getExpenseTransactions,
} from 'src/api/incomes';
import CustomPagination from 'src/components/CustomPagination';
import { Toastify } from 'src/modules';
import store from 'src/stores';
import { formatCurrency, formatDate, SUCCESS } from 'src/utils';

import {
  Actions,
  Circle,
  Container,
  TableBody,
  TableHeader,
  SelectDropdown,
} from './styles';

interface IncomeItem {
  id: number;
  status: string;
  type: string;
  category_id: number | null;
  work_percentage: number | null;
  income_type: string;
  income_id: number;
  description: string;
  transaction_type: string;
  transaction_category: string;
  transaction_classification: string | null;
  merchant_name: string;
  amount: number;
  gross_amount: number;
  tax_amount: number;
  account: string | null;
  created_at: string;
  CIS: number;
  note: string | null;
  is_auto_reconcile: boolean;
  source: string;
  is_tax_taken_off: boolean;
  tax_taken_off_amount: number | null;
  uk_or_foreign: 'uk' | 'foreign';
  user_ref: string;
  income_name: string | null;
  files: any[];
}

interface DividendsInterestTransactionsListProps {
  incomeType: string;
  incomeId: number;
  setOpenAddTransactionForm: (value: boolean) => void;
  openAddTransactionForm: boolean;
  successAddTransaction: boolean;
  onClick: (item: IncomeItem) => void;
  setCurrentTransactionIndex: (index: number) => void;
  TypeTab: string;
}

interface TransactionsListStateProps {
  id: number;
  status: string;
  type: string;
  category_id: number | null;
  work_percentage: number | null;
  income_type: string;
  income_id: number;
  description: string | null;
  transaction_type: string;
  transaction_category: string;
  transaction_classification: string | null;
  merchant_name: string;
  amount: number;
  gross_amount: number;
  tax_amount: number;
  account: string | null;
  created_at: string;
  CIS: number;
  note: string | null;
  is_auto_reconcile: boolean;
  source: string;
  is_tax_taken_off: boolean;
  tax_taken_off_amount: number | null;
  uk_or_foreign: string;
  user_ref: string | null;
  income_name: string | null;
  files: any[];
  checked: boolean;
}

function DividendsInterestTransactionsList({
  incomeType,
  incomeId,
  successAddTransaction,
  onClick,
  setCurrentTransactionIndex,
  TypeTab,
}: DividendsInterestTransactionsListProps) {
  const incomes = store.incomes;

  const [transactionsListState, setTransactionsListState] = useState<
    TransactionsListStateProps[]
  >([]);

  const [hasPagination, setHasPagination] = useState(false);
  const [pagRes, setPagRes] = useState<Pick<
    GeneralPaginationResponse,
    'next_page' | 'total_pages' | 'next_page_url' | 'previous_page_url'
  > | null>(null);
  const [nextPage, setNextPage] = useState(1);

  useEffect(() => {
    incomes.setIndexTransactionSelected(0);
  }, []);

  const { isLoading, refetch } = useQuery(
    [
      TypeTab === 'incomesTab'
        ? 'getIncomeTransactions'
        : 'getExpenseTransactions',
      incomeType,
      incomeId,
      ,
      ,
      ,
      ,
      nextPage,
    ],
    () =>
      TypeTab === 'incomesTab'
        ? getIncomeTransactions(
            incomeType,
            incomeId,
            undefined,
            undefined,
            undefined,
            undefined,
            nextPage,
          )
        : getExpenseTransactions(incomeType, incomeId, nextPage),

    {
      onSuccess: (result) => {
        setTransactionsListState(result.data);
        incomes.setIncomeTransactions(result.data);
        incomes.setPayeOrSelfEmployedTotal(result.totals);

        const {
          has_more,
          next_page,
          total_pages,
          next_page_url,
          previous_page_url,
        } = result;

        setHasPagination(has_more || total_pages !== 1);
        setPagRes({ next_page, total_pages, next_page_url, previous_page_url });
      },
      onError: ({ response }) => {
        Toastify.toast.error(response);
      },
    },
  );

  const { mutate: mutateDeleteTransaction } = useMutation(deleteTransaction, {
    onSuccess: () => {
      Toastify.toast.success(SUCCESS.GENERIC);

      setTimeout(() => {
        refetch();
        incomes.refetchTotals();
        incomes.refetchSummary();
      }, 1000);
    },
    onError: ({ error }) => {
      Toastify.toast.error(error);
    },
  });

  useEffect(() => {
    if (successAddTransaction) {
      refetch();
    }
  }, [successAddTransaction]);

  const onDeleteTransaction = (transactionId: number) => {
    mutateDeleteTransaction(transactionId);
  };

  useEffect(() => {
    const transactionsList = transactionsListState;

    if (!isLoading) {
      transactionsList.forEach(
        (item, index) => (transactionsList[index].checked = false),
      );

      setTransactionsListState(transactionsList);
    }
  }, [isLoading]);

  return (
    <>
      <Container>
        <TableHeader>
          <Row>
            <Col className="headerColumn"></Col>
            <Col className="headerColumn">Reference</Col>
            <Col className="headerColumn">Amount</Col>
            <Col className="headerColumn">Tax</Col>
            <Col className="headerColumn">Tax dedected</Col>
            <Col className="headerColumn">Date</Col>
            <Col className="headerColumn">UK/Foreign</Col>
            <Col className="headerColumn"></Col>
          </Row>
        </TableHeader>

        {!isLoading &&
          transactionsListState.map((item, i) => {
            return (
              <TableBody
                key={i}
                onClick={() => {
                  onClick(item);
                  incomes.setIndexTransactionSelected(i);
                  setCurrentTransactionIndex(i);
                }}
              >
                <Row>
                  <Col
                    className="bodyColumn"
                    style={{ position: 'relative', left: 30 }}
                  >
                    <Circle background="#209782" />
                  </Col>

                  {/* Reference */}
                  <Col className="bodyColumn">
                    {item.merchant_name !== ''
                      ? item.merchant_name
                      : item.description}
                  </Col>

                  {/* Amount */}
                  <Col className="bodyColumn">
                    {formatCurrency(item.gross_amount)}
                  </Col>

                  {/* Tax (Dropdown) */}
                  <Col className="bodyColumn">
                    <SelectDropdown>
                      <select>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </SelectDropdown>
                  </Col>

                  {/* Tax Deducted */}
                  <Col className="bodyColumn">
                    {formatCurrency(item.tax_taken_off_amount || 0)}
                  </Col>

                  {/* Date */}
                  <Col className="bodyColumn">
                    {formatDate(item.created_at)}
                  </Col>

                  {/* UK / Foreign (Dropdown) */}
                  <Col className="bodyColumn">
                    <SelectDropdown>
                      <select value={item.uk_or_foreign} onChange={() => {}}>
                        <option value="UK">UK</option>
                        <option value="Foreign">Foreign</option>
                      </select>
                    </SelectDropdown>
                  </Col>

                  {/* Actions */}
                  <Col className="bodyColumn">
                    <Actions>
                      <Circle background="#F31B1B">
                        <BsFillTrashFill
                          color="white"
                          onClick={() => onDeleteTransaction(item.id)}
                          width={12}
                          height={12}
                        />
                      </Circle>
                      <Circle background="#00C0E0">
                        {item.source === 'openbanking' ? (
                          <BsBank
                            color="white"
                            onClick={() => onDeleteTransaction(item.id)}
                            width={12}
                            height={12}
                          />
                        ) : (
                          <BsUpload
                            color="white"
                            onClick={() => onDeleteTransaction(item.id)}
                            width={12}
                            height={12}
                          />
                        )}
                      </Circle>
                    </Actions>
                  </Col>
                </Row>
              </TableBody>
            );
          })}

        {!isLoading && transactionsListState.length < 1 && (
          <span>No transactions</span>
        )}

        {hasPagination && (
          <CustomPagination
            res={pagRes}
            changePage={(page) => setNextPage(page)}
            position="center"
          />
        )}
      </Container>
    </>
  );
}

export default DividendsInterestTransactionsList;
