import React, { forwardRef, useState, useEffect } from 'react';

import { BsFileEarmarkText } from 'react-icons/bs';
import { useMutation } from 'react-query';

import { updateTransaction } from 'src/api/incomes';
import { useYear } from 'src/hooks';
import { observer, useQueryParam, NumberParam, Toastify } from 'src/modules';
import store from 'src/stores';
import { formatCurrency, SUCCESS } from 'src/utils';

import ViewImage from '../../ExpensesTab/Components/ViewImage';
import { ViewImageSection } from '../../IncomesTab/styles';

import {
  EditContentContainer,
  FormContainer,
  FormTitle,
  FormRow,
  FormColumn,
  Label,
  InputField,
  SelectDropdown,
  ButtonContainer,
  Button,
} from './styles';

type Props = {
  item: any;
  tabIndexClicked: number;
};

export type AllEditTransactionFormHandle = {
  setSelectedTransaction(id: number): void;
};

const EditTransactionForm = forwardRef<AllEditTransactionFormHandle, Props>(
  ({ item, tabIndexClicked }: Props, ref) => {
    const [year] = useYear();
    const [userId] = useQueryParam('id', NumberParam);
    const incomes = store.incomes;

    const [transactionData, setTransactionData] = useState<any>({
      merchant_name: '',
      created_at: '',
      is_tax_taken_off: 'No',
      amount: '',
      tax_amount: '',
      uk_or_foreign: 'UK',
      files: [] as File[],
      imagePreviews: [] as string[],
    });

    const [rawAmount, setRawAmount] = useState<string>('');

    useEffect(() => {
      if (item) {
        setTransactionData({
          ...item,
          created_at: item?.created_at
            ? new Date(item.created_at).toISOString().split('T')[0]
            : '',
          files: item.files || [],
          imagePreviews: item?.files
            ? item.files.map((file: { url: string }) => file)
            : [],
        });

        if (item?.amount) {
          setRawAmount(formatCurrency(item.amount));
        }
      }
    }, [item]);

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value.replace(/[^\d.,]/g, '');
      setRawAmount(rawValue);
    };

    const handleAmountBlur = () => {
      if (rawAmount) {
        const formattedAmount = formatCurrency(
          parseFloat(rawAmount.replace(',', '.')) || 0,
        );
        setRawAmount(formattedAmount);
      }
    };

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
      const { name, value } = event.target;

      setTransactionData((prev) => ({
        ...prev,
        [name]: name === 'is_tax_taken_off' ? value === 'Yes' : value,
      }));
    };

    const onUploadProof = async (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { files } = event.target;
      if (!files) return;

      const validExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
      const selectedFiles = Array.from(files);

      const filteredFiles = selectedFiles.filter((file) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        return fileExtension && validExtensions.includes(fileExtension);
      });

      if (filteredFiles.length !== selectedFiles.length) {
        Toastify.toast.error(
          'Some files were not uploaded due to invalid format.',
        );
      }
      const imagePreviews = await Promise.all(
        filteredFiles.map((file) => {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => resolve(reader.result as string);
          });
        }),
      );

      setTransactionData((prev) => ({
        ...prev,
        files: [...prev.files, ...filteredFiles],
        imagePreviews: imagePreviews,
      }));
    };

    const isTaxYes =
      transactionData?.is_tax_taken_off === 'Yes' ||
      transactionData?.is_tax_taken_off === true;

    const allFieldsFilled =
      transactionData?.merchant_name &&
      transactionData?.created_at &&
      transactionData?.amount !== undefined &&
      (transactionData?.is_tax_taken_off === 'No' ||
        transactionData?.tax_amount !== undefined) &&
      transactionData?.uk_or_foreign;

    const { mutate: mutationUpdateTransaction, isLoading } = useMutation(
      'updateTransaction',
      updateTransaction,
      {
        onSuccess() {
          Toastify.toast.success(SUCCESS.GENERIC);
          incomes.refetchIncomeTransactions();
        },
        onError({ error }) {
          Toastify.toast.error(error);
        },
      },
    );

    const onUpdateTransaction = () => {
      if (!transactionData) {
        console.error('Error: Transaction data is missing.');
        return;
      }

      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('user_id', userId);
      formData.append('tax_year', year);
      formData.append('income_id', transactionData.income_id.toString());
      formData.append('income_type', transactionData.income_type);
      formData.append('reconciliation_type', 'income');
      formData.append('merchant_name', transactionData.merchant_name);
      formData.append('created_at', transactionData.created_at);
      formData.append('amount', transactionData.amount.toString());
      formData.append(
        'is_tax_taken_off',
        transactionData.is_tax_taken_off ? 'true' : 'false',
      );

      formData.append('tax_amount', transactionData.tax_amount.toString());
      formData.append('uk_or_foreign', transactionData.uk_or_foreign);

      transactionData.files.forEach((file) => {
        formData.append('files[]', file);
      });

      mutationUpdateTransaction({
        transaction_id: transactionData.id,
        formData,
      });
    };

    return (
      <EditContentContainer>
        <FormContainer>
          <FormTitle>
            {tabIndexClicked === 0
              ? 'Add Dividend Transaction'
              : 'Add Interest Transaction'}
          </FormTitle>

          <FormRow>
            <FormColumn>
              <Label>Reference</Label>
              <InputField
                type="text"
                name="merchant_name"
                value={transactionData?.merchant_name || ''}
                onChange={handleChange}
              />
            </FormColumn>

            <FormColumn>
              <Label>Date</Label>
              <InputField
                type="date"
                name="created_at"
                value={transactionData?.created_at || ''}
                onChange={handleChange}
              />
            </FormColumn>

            <FormColumn>
              <Label>Tax</Label>
              <SelectDropdown>
                <select
                  name="is_tax_taken_off"
                  value={transactionData?.is_tax_taken_off || 'No'}
                  onChange={handleChange}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </SelectDropdown>
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn>
              <Label>Amount</Label>
              <InputField
                type="text"
                name="amount"
                value={rawAmount}
                onChange={handleAmountChange}
                onBlur={handleAmountBlur}
              />
            </FormColumn>

            <FormColumn>
              {tabIndexClicked === 0 ? (
                <>
                  <Label>Dividend Tax</Label>
                  <InputField
                    type="text"
                    name="tax_amount"
                    value={formatCurrency(transactionData?.tax_amount || 0)}
                    onChange={handleChange}
                    disabled={!isTaxYes} // Disable if Tax is "No"
                  />
                </>
              ) : (
                <>
                  <Label>UK/Foreign</Label>
                  <SelectDropdown>
                    <select
                      name="uk_or_foreign"
                      value={transactionData?.uk_or_foreign || 'UK'}
                      onChange={handleChange}
                    >
                      <option value="UK">UK</option>
                      <option value="Foreign">Foreign</option>
                    </select>
                  </SelectDropdown>
                </>
              )}
            </FormColumn>

            {tabIndexClicked === 0 ? (
              <FormColumn>
                <Label>UK/Foreign</Label>
                <SelectDropdown>
                  <select
                    name="uk_or_foreign"
                    value={transactionData?.uk_or_foreign || 'UK'}
                    onChange={handleChange}
                  >
                    <option value="UK">UK</option>
                    <option value="Foreign">Foreign</option>
                  </select>
                </SelectDropdown>
              </FormColumn>
            ) : (
              <FormColumn></FormColumn>
            )}
          </FormRow>

          <input
            type="file"
            accept=".pdf, .jpg, .jpeg, .png"
            onChange={onUploadProof}
            hidden
            id="uploadFiles"
            multiple
          />

          {transactionData?.files.length > 0 && (
            <ViewImageSection>
              <ViewImage
                transactions={{ files: transactionData.imagePreviews }}
              />
            </ViewImageSection>
          )}

          <ButtonContainer>
            <label htmlFor="uploadFiles">
              <Button as="span">
                <BsFileEarmarkText size={18} color="#191424" />
                <span>Upload</span>
              </Button>
            </label>

            <Button onClick={onUpdateTransaction} disabled={!allFieldsFilled}>
              Submit
            </Button>
          </ButtonContainer>
        </FormContainer>
      </EditContentContainer>
    );
  },
);

export default observer(EditTransactionForm);
