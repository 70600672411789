import styled from 'styled-components';

type Props = {
  background?: string;
};

export const Container = styled.div`
  overflow-x: auto;
  margin-top: 30px;
`;

export const TableHeader = styled.div`
  border-bottom: 1px solid #111;

  .headerColumn {
    display: flex;
    padding: 0 0 10px 0;
    text-align: left;
    color: #191424;
    font-weight: 600;
  }
`;

export const TableBody = styled.div`
  &:nth-child(even) {
    background-color: #f6f9fc;
  }

  &:hover {
    background-color: #57e4cd4d;
  }

  .bodyColumn {
    display: flex;
    padding: 16px 0;
    cursor: pointer;
  }
`;

export const Circle = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.background};
  svg {
    color: white;
    width: 14px;
    height: 14px;
  }
`;

export const Select = styled.select`
  padding: 2px 10px;
`;

export const Actions = styled.div`
  display: flex;
  column-gap: 20px;
  margin: 0 auto;
`;

export const SectionAddNewItem = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const Checkbox = styled.input`
  width: 32px;
  height: 32px;
  border: 1px solid #d8d8d8;
  border-radius: 8px !important;
`;

export const SelectDropdown = styled.div`
  select {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: white;
    font-size: 14px;
    cursor: pointer;
  }

  select:focus {
    outline: none;
    border-color: #209782;
  }
`;
