import React, { useState } from 'react';

import { BsFileEarmarkText } from 'react-icons/bs';

import { observer, Row, Toastify } from 'src/modules';
import { formatCurrency } from 'src/utils';

import {
  AddCancelSection,
  CancelButton,
} from '../../ExpensesTab/Components/TabSelfEmployed/styles';
import ViewImage from '../../ExpensesTab/Components/ViewImage';
import { ViewImageSection } from '../../IncomesTab/styles';
import {
  EditContentContainer,
  FormContainer,
  FormTitle,
  FormRow,
  FormColumn,
  Label,
  InputField,
  SelectDropdown,
  ButtonContainer,
  Button,
} from '../EditTransactionForm/styles';

type Props = {
  openAddTransactionForm: boolean;
  onCancelAddTransaction: () => void;
  tabIndexClicked: number;
  onAddTransaction: (transactionData: any) => void;
};

const AddTransactionForm: React.FC<Props> = ({
  openAddTransactionForm,
  onCancelAddTransaction,
  tabIndexClicked,
  onAddTransaction,
}) => {
  const [transactionData, setTransactionData] = useState({
    merchant_name: '',
    created_at: new Date().toISOString().split('T')[0],
    is_tax_taken_off: 'No',
    amount: '',
    tax_amount: '',
    uk_or_foreign: 'uk',
    files: [] as File[],
  });

  const [rawAmount, setRawAmount] = useState(transactionData?.amount || '');

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^\d.]/g, '');
    setRawAmount(value);
    setTransactionData((prev) => ({
      ...prev,
      amount: value,
    }));
  };

  const handleAmountBlur = () => {
    setRawAmount(formatCurrency(transactionData?.amount || 0));
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setTransactionData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onUploadProof = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;

    const validExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
    const selectedFiles = Array.from(files);

    const filteredFiles = selectedFiles.filter((file) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      return fileExtension && validExtensions.includes(fileExtension);
    });

    if (filteredFiles.length !== selectedFiles.length) {
      Toastify.toast.error(
        'Some files were not uploaded due to invalid format.',
      );
    }
    const imagePreviews = await Promise.all(
      filteredFiles.map((file) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => resolve(reader.result as string);
        });
      }),
    );

    setTransactionData((prev) => ({
      ...prev,
      files: [...prev.files, ...filteredFiles],
      imagePreviews: imagePreviews,
    }));
  };

  const handleSubmit = () => {
    onAddTransaction(transactionData);
  };

  const isFormValid =
    transactionData.merchant_name &&
    transactionData.created_at &&
    transactionData.amount !== undefined &&
    (transactionData.is_tax_taken_off === 'No' ||
      transactionData.tax_amount !== undefined) &&
    transactionData.uk_or_foreign;

  return (
    openAddTransactionForm && (
      <EditContentContainer>
        <FormContainer>
          <FormTitle>
            {tabIndexClicked === 0
              ? 'Add Dividend Transaction'
              : 'Add Interest Transaction'}
          </FormTitle>

          <FormRow>
            <FormColumn>
              <Label>Reference</Label>
              <InputField
                type="text"
                name="merchant_name"
                value={transactionData?.merchant_name || ''}
                onChange={handleChange}
              />
            </FormColumn>
            <FormColumn>
              <Label>Date</Label>
              <InputField
                type="date"
                name="created_at"
                value={transactionData.created_at}
                onChange={handleChange}
              />
            </FormColumn>

            <FormColumn>
              <Label>Tax</Label>
              <SelectDropdown>
                <select
                  name="is_tax_taken_off"
                  value={transactionData?.is_tax_taken_off || 'No'}
                  onChange={handleChange}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </SelectDropdown>
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn>
              <Label>Amount</Label>
              <InputField
                type="text"
                name="amount"
                value={rawAmount}
                onChange={handleAmountChange}
                onBlur={handleAmountBlur}
              />
            </FormColumn>
            <FormColumn>
              {tabIndexClicked === 0 ? (
                <>
                  <Label>Dividend Tax</Label>
                  <InputField
                    type="text"
                    name="tax_amount"
                    value={formatCurrency(transactionData?.tax_amount || 0)}
                    onChange={handleChange}
                    disabled={transactionData?.is_tax_taken_off === 'No'} // Disable if Tax is "No"
                  />
                </>
              ) : (
                <>
                  <Label>UK/Foreign</Label>
                  <SelectDropdown>
                    <select
                      name="uk_or_foreign"
                      value={transactionData?.uk_or_foreign || 'uk'}
                      onChange={handleChange}
                    >
                      <option value="uk">UK</option>
                      <option value="foreign">Foreign</option>
                    </select>
                  </SelectDropdown>
                </>
              )}
            </FormColumn>

            {tabIndexClicked === 0 ? (
              <FormColumn>
                <Label>UK/Foreign</Label>
                <SelectDropdown>
                  <select
                    name="uk_or_foreign"
                    value={transactionData?.uk_or_foreign || 'UK'}
                    onChange={handleChange}
                  >
                    <option value="UK">UK</option>
                    <option value="Foreign">Foreign</option>
                  </select>
                </SelectDropdown>
              </FormColumn>
            ) : (
              <FormColumn></FormColumn>
            )}
          </FormRow>

          <input
            type="file"
            accept=".pdf, .jpg, .jpeg, .png"
            onChange={onUploadProof}
            hidden
            id="uploadFiles"
            multiple
          />

          <ButtonContainer>
            <label htmlFor="uploadFiles">
              <Button as="span">
                <BsFileEarmarkText size={18} color="#191424" />
                <span>Upload</span>
              </Button>
            </label>

            <ViewImageSection>
              <ViewImage
                transactions={{ files: transactionData?.imagePreviews }}
              />
            </ViewImageSection>

            <Row>
              <AddCancelSection>
                <Button
                  onClick={handleSubmit}
                  disabled={!isFormValid}
                  style={{ position: 'relative' }}
                >
                  <span>Add</span>
                </Button>
                <CancelButton onClick={onCancelAddTransaction}>
                  <span>Cancel</span>
                </CancelButton>
              </AddCancelSection>
            </Row>
          </ButtonContainer>
        </FormContainer>
      </EditContentContainer>
    )
  );
};

export default observer(AddTransactionForm);
