import React, { useEffect, useState, useRef } from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import { NumberParam, useQueryParam } from 'use-query-params';

import { addTransaction } from 'src/api/incomes';
import { getTaxIncomeListingDividendInterest } from 'src/api/users';
import EditInputPie from 'src/components/EditInputPie';
import { useYear } from 'src/hooks';
import { observer, Toastify } from 'src/modules';
import store from 'src/stores';
import { formatCurrency, SUCCESS } from 'src/utils';
import { handleLogo } from 'src/utils/incomes-expenses';

import { AllEditTransactionFormHandle } from '../../IncomesTab/Components/EditTransactionForm';
import {
  IncomeHeader,
  IncomeContainer,
  IncomeHeaderFields,
  IncomeInputsContainer,
  TransactionsSection,
  ContentContainer,
  TabsHeader,
  TabsHeaderContent,
  NewItemButton,
} from '../../IncomesTab/styles';
import AddTransactionForm from '../AddTransactionForm';
import DividendsInterestTransactionsList from '../DividendsInterestTransactionsList';
import EditTransactionForm from '../EditTransactionForm';

import {
  IncomeGrossSection,
  IncomeTaxSection,
  Logo,
  TabDividends,
  TabDetails,
  AddButtonContainer,
} from './styles';

interface IncomeItem {
  id: number;
  status: string;
  type: string;
  category_id: number | null;
  work_percentage: number | null;
  income_type: string;
  income_id: number;
  description: string;
  transaction_type: string;
  transaction_category: string;
  transaction_classification: string | null;
  merchant_name: string;
  amount: number;
  gross_amount: number;
  tax_amount: number;
  account: string | null;
  created_at: string;
  CIS: number;
  note: string | null;
  is_auto_reconcile: boolean;
  source: string;
  is_tax_taken_off: boolean;
  tax_taken_off_amount: number | null;
  uk_or_foreign: 'uk' | 'foreign';
  user_ref: string;
  income_name: string | null;
  files: any[];
}

function TabDividendsInterest() {
  // @refs
  const editTransactionRef = useRef<AllEditTransactionFormHandle>(null);

  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const incomes = store.incomes;

  const [dividendsInterestList, setDividendsInterestList] = useState([]);
  const [tabIndexClicked, setTabIndexClicked] = useState(0);
  const [tabIdClicked, setTabIdClicked] = useState(0);
  const [incomeIdSelected, setIncomeIdSelected] = useState<IncomeItem>();
  const [openAddTransactionForm, setOpenAddTransactionForm] = useState(false);

  const { data, isLoading } = useQuery(
    'getTaxIncomeListingDividendInterest',
    () =>
      getTaxIncomeListingDividendInterest(
        userId ?? 0,
        year,
        'dividends_interest',
      ),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  useEffect(() => {
    if (!isLoading && data.length > 0) {
      // Ordena para garantir que 'Dividends' seja o primeiro
      const sortedList = [...data].sort((a, b) =>
        a.dividends_or_interest === 'dividends' ? -1 : 1,
      );

      setDividendsInterestList(sortedList);

      // Garante que a aba inicial seja "Dividends"
      const dividendsTab = sortedList.find(
        (item) => item.dividends_or_interest === 'dividends',
      );

      if (dividendsTab) {
        setTabIdClicked(dividendsTab.id);
        setTabIndexClicked(sortedList.indexOf(dividendsTab));
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (dividendsInterestList.length > 0 && tabIdClicked === 0) {
      setTabIdClicked(dividendsInterestList[0].id);
    }
  }, [dividendsInterestList]);

  const onTabChange = (tabId: number, index: number) => {
    setIncomeIdSelected(null);
    setTabIdClicked(tabId);
    setTabIndexClicked(index);
  };

  const { mutate: mutationAddTransaction, isSuccess } = useMutation(
    'addTransaction',
    addTransaction,
    {
      onSuccess() {
        Toastify.toast.success(SUCCESS.GENERIC);

        setTimeout(() => {
          setOpenAddTransactionForm(false);
          incomes.refetchTotals();
          incomes.refetchSummary();
        }, 2000);
      },
      onError({ response }) {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const onAddTransaction = (transactionData: any) => {
    const formData = new FormData();

    formData.append('user_id', userId);
    formData.append('tax_year', year);
    formData.append('income_id', tabIdClicked);
    formData.append('income_type', 'dividends_interest');
    formData.append('reconciliation_type', 'income');
    formData.append('client_name', transactionData.merchant_name);
    formData.append('date', transactionData.created_at);
    formData.append('amount', transactionData.amount);
    formData.append(
      'is_tax_taken_off',
      transactionData.is_tax_taken_off === 'Yes' ? 'true' : 'false',
    );
    if (transactionData.tax_amount) {
      formData.append('tax_amount', transactionData.tax_amount);
    }
    formData.append('uk_or_foreign', transactionData.uk_or_foreign);

    if (transactionData.files && transactionData.files.length > 0) {
      transactionData.files.forEach((file: File, index: number) => {
        formData.append(`files[${index}]`, file);
      });
    }

    mutationAddTransaction(formData);
  };

  const handleTransactionSelect = (item: IncomeItem) => {
    editTransactionRef.current?.setSelectedTransaction(item.id);
    setIncomeIdSelected(item);
  };

  const dividendsItem = dividendsInterestList.find(
    (item) => item.dividends_or_interest === 'dividends',
  );

  const tabIcons: Record<string, JSX.Element> = {
    dividends: (
      <img
        src="/images/incomesTab/Dividends.png"
        width="24"
        height="27"
        alt=""
      />
    ),
    interest: (
      <img
        src="/images/incomesTab/Interest.png"
        width="24"
        height="27"
        alt=""
      />
    ),
  };

  return (
    <div>
      {!isLoading && (
        <IncomeContainer>
          <IncomeHeader>
            <div>
              <Logo
                src={handleLogo(
                  'dividends_interest',
                  dividendsInterestList?.find((item) => item?.income_type)
                    ?.income_type ?? '',
                )}
                alt=""
              />
            </div>
            <IncomeInputsContainer>
              <IncomeHeaderFields>
                <Row>
                  <Col>
                    <IncomeGrossSection>
                      <EditInputPie
                        id="total_dividends"
                        label="Total Dividends"
                        name="total_dividends"
                        type="text"
                        value={formatCurrency(
                          dividendsInterestList.find(
                            (item) =>
                              item?.dividends_or_interest === 'dividends',
                          )?.total_amount || 0,
                        )}
                        onChange={() => {}}
                        editMode={false}
                        disabled
                      />
                    </IncomeGrossSection>

                    <IncomeTaxSection>
                      <EditInputPie
                        id="total_interest"
                        label="Total Interest"
                        name="total_interest"
                        type="text"
                        value={formatCurrency(
                          dividendsInterestList.find(
                            (item) =>
                              item?.dividends_or_interest === 'interest',
                          )?.total_amount || 0,
                        )}
                        onChange={() => {}}
                        editMode={false}
                        disabled
                      />
                    </IncomeTaxSection>
                  </Col>

                  <Col>
                    <EditInputPie
                      label="Total dividends tax"
                      id="total_dividends_tax"
                      name="total_dividends_tax"
                      type="text"
                      value={formatCurrency(
                        dividendsItem ? dividendsItem.tax_taken_off_amount : 0,
                      )}
                      onChange={() => {}}
                      editMode={false}
                      disabled
                    />
                  </Col>
                </Row>
              </IncomeHeaderFields>
            </IncomeInputsContainer>
          </IncomeHeader>

          <div>
            <TabsHeader>
              <TabsHeaderContent>
                {dividendsInterestList.length > 0 &&
                  dividendsInterestList.map((item, index) => (
                    <TabDividends
                      key={index}
                      onClick={() => onTabChange(item?.id, index)}
                      tabIndexClicked={index === tabIndexClicked}
                    >
                      <TabDetails>
                        {tabIcons[item.dividends_or_interest]}
                        <span className="title">{item?.name}</span>
                        <span className="value">
                          {formatCurrency(item?.total_amount)}
                        </span>
                      </TabDetails>
                    </TabDividends>
                  ))}
              </TabsHeaderContent>
            </TabsHeader>
          </div>

          <ContentContainer openContent={true}>
            <TransactionsSection>
              {dividendsInterestList.length > 0 && (
                <>
                  <TransactionsSection>
                    <h3>Transactions</h3>
                    <DividendsInterestTransactionsList
                      incomeType="dividends_interest"
                      TypeTab="incomesTab"
                      incomeId={tabIdClicked}
                      setOpenAddTransactionForm={setOpenAddTransactionForm}
                      openAddTransactionForm={openAddTransactionForm}
                      successAddTransaction={false}
                      setCurrentTransactionIndex={() => {}}
                      onClick={handleTransactionSelect}
                    />
                  </TransactionsSection>
                </>
              )}
            </TransactionsSection>
          </ContentContainer>

          <AddButtonContainer>
            <NewItemButton onClick={() => setOpenAddTransactionForm(true)}>
              <AiOutlinePlus size={18} />
            </NewItemButton>
          </AddButtonContainer>

          {openAddTransactionForm && (
            <AddTransactionForm
              openAddTransactionForm={openAddTransactionForm}
              onCancelAddTransaction={() => setOpenAddTransactionForm(false)}
              tabIndexClicked={tabIndexClicked}
              onAddTransaction={onAddTransaction}
            />
          )}

          {incomeIdSelected && (
            <EditTransactionForm
              item={incomeIdSelected}
              tabIndexClicked={tabIndexClicked}
              ref={editTransactionRef}
            />
          )}
        </IncomeContainer>
      )}
    </div>
  );
}

export default observer(TabDividendsInterest);
