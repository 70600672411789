import styled from 'styled-components';

export const EditContentContainer = styled.div`
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;

  & > h3 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    flex: 1;
  }
`;

export const FormContainer = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  width: 100%;
  display: block;
`;

export const FormRow = styled.div`
  display: flex;
  gap: 60px;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const FormColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 4px;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 14px;
  &:disabled {
    background: #e0e0e0;
  }
`;

export const SelectDropdown = styled.div`
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    font-size: 14px;
    background: #fff;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  align-items: flex-start;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: #57e4cd;
  height: 38px;
  border: none;
  border-radius: 48px;
  padding: 0 24px;
  align-self: flex-start;

  > span {
    font-weight: 600;
  }
`;

export const UploadContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f3f3;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  color: #333;

  button {
    background: none;
    border: none;
    color: red;
    font-weight: bold;
    cursor: pointer;
  }
`;
