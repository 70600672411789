import { QueryFunctionContext } from 'react-query';

import { ICustomer } from 'src/models/ICustomer';
import { CustomerInformation } from 'src/utils/types/Customer';

import { getCurrentFiscalYear } from '../../../src/utils/helpers/dateFormatters';
import request from '../request';

export const searchUsers = async (
  query = '',
  year = getCurrentFiscalYear(),
): Promise<ICustomer[]> => {
  const { data } = await request.get(`/users?search=${query}&tax_year=${year}`);

  //   const { data } = await request.get(
  //     `/users/list-users/get-auto-complete?search=${query}`,
  //   );

  return data.data;
};

export const listUsers = async (query = ''): Promise<ListUsersResponse> => {
  //   const { data } = await request.get(`/users/list-users${query}`);
  const searchParams = new URLSearchParams(query);
  const hasYear = searchParams.has('tax_year');
  if (!hasYear) {
    searchParams.append('tax_year', getCurrentFiscalYear().toString());
  }
  const { data } = await request.get(`/users?${searchParams.toString()}`);

  return data;
};

export const getTaskHistory = async (
  id: number,
  filter: string,
): Promise<TaskHistoryData[]> => {
  const { data } = await request.get(
    `/users/task-history?user_id=${id}&type=${filter}`,
  );

  return data.data;
};

export const getNotificationsList = async (ids: number[]) => {
  const { data } = await request.post(
    `/users/list-users/get-notification-counts`,
    {
      user_ids: [...ids],
    },
  );

  return data.data;
};

export const deleteUser = async (userId: number) => {
  const { data } = await request.delete(
    `/users/delete-account?user_id=${userId}`,
  );

  return data;
};

// add disable enpoint
/* export const disableUser = async (userId: number) => {
  const { data } = await request.delete(
    `/users/add disable enpoint here ?user_id=${userId}`,
  );

  return data;
}; */

export const setYearClosed = async (
  userId: number,
  taxYear: number,
  status: boolean,
) => {
  const { data } = await request.post(`/users/set-year-closed`, {
    user_id: userId,
    tax_year: taxYear,
    status,
  });

  return data;
};

export const enableUserAccount = async (userId: number) => {
  const { data } = await request.put(
    `/users/enable-account/?user_id=${userId}`,
  );

  return data;
};

export const enableUserDisabled = async (userId: number) => {
  const { data } = await request.put(`/users/change-status/?user_id=${userId}`);

  return data;
};

export const postToggleSaction = async (user_id: number[]) => {
  const { data } = await request.post(`/sanction/toggle-sanction`, {
    user_id,
  });

  return data.data;
};

type ContactStatus = {
  user_id: number;
  tax_year: number;
  status_label_ids: number[];
};

export const addContactStatus = async ({
  user_id,
  tax_year,
  status_label_ids,
}: ContactStatus) => {
  const { data } = await request.post(`/users/add-contact-status`, {
    user_id,
    tax_year,
    status_label_ids,
  });

  return data.data;
};
export const getUsersByAssistant = async (assistant_id: number) => {
  const { data } = await request.get(
    `/users/by-assistant?assistant_id=${assistant_id}`,
  );

  return data.data;
};

export const getTotalUsersInfoByAssistant = async (assistant_id: number) => {
  const { data } = await request.get(
    `/users/by-assistant/total-users-info?assistant_id=${assistant_id}`,
  );

  return data;
};

export const getPersonalInformation = async (
  user_id: number,
): Promise<CustomerInformation> => {
  const { data } = await request.get(
    `v2/users/personal-info?user_id=${user_id}`,
  );

  return data;
};

export const getCurrentStatus = async (user_id: number, year: number) => {
  const { data } = await request.get(
    `users/get-request-status?user_id=${user_id}&year=${year}`,
  );

  return data;
};

export const deleteContactStatus = async (id: number) => {
  const { data } = await request.get(`/users/remove-contact-status?id=${id}`);

  return data;
};

export const getTaxIncomeSummary = async (
  user_id: number,
  tax_year: number,
) => {
  const { data } = await request.get(
    `/users/tax-income/summary?user_id=${user_id}&tax_year=${tax_year}&type=credit`,
  );

  return data;
};

export const getTaxIncomeListing = async (
  user_id: number,
  tax_year: number,
  income_type: string,
) => {
  const { data } = await request.get(
    `/users/tax-income/listing?user_id=${user_id}&tax_year=${tax_year}&income_type=${income_type}`,
  );

  return data;
};

export const getTaxIncomeListingDividendInterest = async (
  user_id: number,
  tax_year: number,
  income_type: 'dividends_interest',
) => {
  const { data } = await request.get(
    `/users/tax-income/listing?user_id=${user_id}&tax_year=${tax_year}&income_type=${income_type}`,
  );

  return data;
};

export const getUserBanksInformation = async (userId: string | number) => {
  const { data } = await request.get(
    `v2/users/bank-information?user=${userId}`,
  );

  return data.data;
};

export const getTaxIncomeListingAll = async (
  user_id: number,
  tax_year: number,
) => {
  const { data } = await request.get(
    `/users/tax-income/listing?user_id=${user_id}&tax_year=${tax_year}`,
  );

  return data;
};

export const getUserProofId = async (userId: string | number) => {
  const { data } = await request.get(
    `v2/users/proof-id-information?user_id=${userId}`,
  );

  return data;
};

export const getTrafficLight = async (user_id: number, tax_year: number) => {
  const { data } = await request.get(
    `/v2/users/traffic-light?user_id=${user_id}&tax_year=${tax_year}`,
  );

  return data;
};

export const changeProofIdStatus = async (user_id: number) => {
  const { data } = await request.post(`/users/change-proof-id-status`, {
    user_id,
  });
  return data.data;
};

export const changeProofAddressStatus = async (user_id: number) => {
  const { data } = await request.post(`/users/change-proof-address-status`, {
    user_id,
  });
  return data.data;
};

export const uploadProofId = async (formData: any) => {
  const { data } = await request.post(`/users/proof-id-upload`, formData);
  return data.data;
};

export const uploadProofAddress = async (formData: any) => {
  const { data } = await request.post(`/users/proof-address-upload`, formData);
  return data.data;
};

export const getExpensesCategories = async (income_type: string) => {
  const { data } = await request.get(
    `/expenses/expenses-categories?income_type=${income_type}`,
  );

  return data;
};

export const searchUsersAutoComplete = async (
  query = '',
): Promise<ICustomer[]> => {
  const { data } = await request.get(
    `/users/list-users/get-auto-complete?search=${query}`,
  );

  return data.data;
};

export const getTotalCustomers = async () => {
  const { data } = await request.get(`/users/total-customers`);

  return data;
};

export const getPurchaseHistory = async (
  nextPage: number,
  name?: string,
  filter?: string,
  is_purchase_contacted?: string,
  status?: string,
  is_active?: string,
  service?: string,
) => {
  let url = `/users/purchase-history?per_page=20&page=${nextPage}`;

  if (name) url += `&customer_name=${name}`;

  if (filter) url += `&filter_product=${filter}`;

  if (is_purchase_contacted)
    url += `&is_purchase_contacted=${is_purchase_contacted}`;

  if (status) url += `&status=${status}`;

  if (is_active) url += `&is_active=${is_active}`;

  if (service) url += `&service=${service}`;

  const { data } = await request.get(url);

  return data;
};

export const getExportCustomers = async (
  tax_year: number,
  from: string,
  to: string,
) => {
  const { data } = await request.get(
    `/users/export-customers?tax_year=${tax_year}&from=${from}&to=${to}`,
  );

  return data;
};

export const updatePurchaseContacted = async (id: number) => {
  const { data } = await request.put(`/users/purchase-contacted/${id}`);

  return data;
};
