import styled from 'styled-components';

type Props = {
  editMode?: boolean;
  maxWidth?: string;
  height?: string;
  fontSize?: string;
  padding?: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;

  > label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
  }

  .pound {
    position: absolute;
    top: 47px;
    left: 17px;
    font-size: 18px;
  }
`;

export const Input = styled.input<Props>`
  border-radius: 4px;
  border: ${(props) => (props.editMode ? '1px solid #222' : 'none')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '240px')};
  height: ${(props) => (props.height ? props.height : 'initial')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : 'initial')};
  padding: ${(props) => (props.padding ? props.padding : 'initial')};

  &:disabled {
    background-color: #fff;
  }

  &::placeholder {
    font-size: 18px;
  }
`;
