export const handleLogo = (type: any, self_employment_type: string) => {
  if (type === 'employment') return '/images/incomesTab/PAYE.png';
  else if (type === 'self_employed') {
    if (self_employment_type === 'freelancer')
      return '/images/incomesTab/Freelancer.png';
    if (self_employment_type === 'gig_worker')
      return '/images/incomesTab/Gig-Worker.png';
    if (self_employment_type === 'small_business')
      return '/images/incomesTab/Small-Business.png';
    if (self_employment_type === 'other')
      return '/images/incomesTab/Something-Else.png';
    if (self_employment_type === 'cis') return '/images/incomesTab/CIS.png';
  } else if (type === 'dividends_interest') {
    return '/images/incomesTab/Dividends-Interest.png';
  }
};
